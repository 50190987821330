import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from "@material-ui/core";
import { ChangeEvent, FocusEvent, useCallback, useMemo } from "react";
import { FieldValues } from "react-hook-form";
import { IRhfControl } from ".";
import { durationStr, MILLISECONDS_PER_MINUTE, parseDuration } from "../../../utils/dates";
import { deepmerge } from "../../../utils/objects";
import { RhfControlled, RhfControlledProps, RhfControlledRenderProps } from "./RhfControlled";

/**
 * takes a string duration and returns the duration in minutes
 * @param value duration string
 * @returns a number of minutes
 */
export const durationControlParseDuration = (value: string): number | undefined => {
  if (!value) return;

  let stringToParse: string = value.toLowerCase();

  // If user does not add min/hr to input assume:
  if (/^\d+$/.test(value)) {
    // 9 or less they mean hours
    if (parseInt(value) <= 9) {
      stringToParse = value + " hr";
    } else {
      // 10 or more they mean minutes
      stringToParse = value + " min";
    }
  }

  const ms = parseDuration(stringToParse);
  return ms && ms / MILLISECONDS_PER_MINUTE;
};

export const DurationControlValidators = {
  min: (value: string, min: string, error?: string) => {
    const parsed = durationControlParseDuration(value);
    const minParsed = durationControlParseDuration(min);
    return !!parsed && !!minParsed && parsed < minParsed ? error || `Minimum duration is ${min}` : true;
  },
  max: (value: string, max: string, error?: string) => {
    const parsed = durationControlParseDuration(value);
    const maxParsed = durationControlParseDuration(max);
    return !!parsed && !!maxParsed && parsed > maxParsed ? error || `Maximum duration is ${max}` : true;
  },
  interval: (value: string, interval: string) => {
    const parsed = durationControlParseDuration(value);
    const intervalParsed = durationControlParseDuration(interval);
    return !!parsed && !!intervalParsed && parsed % intervalParsed !== 0 ? `Must be an interval of ${interval}` : true;
  },
};

export type DurationControlRenderProps = MuiTextFieldProps & RhfControlledRenderProps;

export type DurationControlProps = Omit<MuiTextFieldProps, "required"> &
  Omit<RhfControlledProps<FieldValues, DurationControlRenderProps>, "render"> & {
    required?: string | boolean;
  };

/**
 * Controlled field for entry of duration.
 * @param props
 * @deprecated use `DurationField`, `RhfDurationField` instead
 */
export const DurationControl: IRhfControl<DurationControlProps> = ({ rules, required, helperText, ...rest }) => {
  const merged: DurationControlProps["rules"] = useMemo(
    () =>
      deepmerge({}, rules, {
        required,
        validate: {
          parseable: (v: string) => (!!v ? !!durationControlParseDuration(v) || "invalid duration" : true),
        },
      }),
    [rules, required]
  );

  const render = useCallback(
    ({ field, fieldState, formState, ...rest }: DurationControlRenderProps) => {
      const { name, value, ref: inputRef, onChange, onBlur } = field;

      const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e);
        rest.onChange?.(e);
      };

      const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        const parsed = durationControlParseDuration(e.target.value);

        if (!!parsed) {
          onChange(durationStr(parsed * 60, true));
        }

        onBlur();
        rest.onBlur?.(e);
      };

      return (
        <MuiTextField
          autoComplete="off"
          variant="outlined"
          {...rest}
          inputRef={inputRef}
          {...{ name, value, onChange: handleChange, onBlur: handleBlur }}
          error={!!fieldState.error}
          helperText={!!fieldState.error?.message ? fieldState.error?.message : helperText}
        />
      );
    },
    [helperText]
  );

  return <RhfControlled {...rest} rules={merged} render={render} />;
};

DurationControl.isControl = true;
DurationControl.isController = true;
